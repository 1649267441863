#add-event-form
{
  width: 400px;

  .loader-container{
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

button.add-event-form
{
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: solid 0.5px white;
  color: white;
  background-color: #e43e21;
}

form
{
  h4{  
    height: 20px;
    margin: 10px 0;
    text-align: left;
    text-transform: uppercase;
    border-bottom: 0.5px solid #000;
  }

  h5{
    font-size: 1rem;
    font-style: italic;
    font-weight: normal;
    margin: 0 0 10px;
  }

  .react-select
  {
    width: 100%;
  }

  .date .content
  {
    display: flex;
    flex-flow: row;
    align-items: center;

    .end-date
    {
      margin-left: 10px;

      input[type=date]
      {
        display: none;

        &.on{
          display: initial;
        }
      }
    }

    label{
      margin-right: 5px;
    }
  }

  .custom-fields
  {
    margin-top: 10px;

    input{
      margin-bottom: 10px;
    }
  }

  .scope
  {
    .react-select
    {
      display: none;

      &.on{
        display: block;
      }
    }

    & > div{
      padding: 5px 0;
    }

    input[type=file]
    {
      display: none;
      border: none;

      &.on{
        display: initial;
      }
    }
    
    .urls
    {
      .list,
      .add-url{
        display: none;

        &.on{
          display: initial;
        }
      }
      
      .add-url
      {
        margin-left: 5px;
        background-color: #e43e21;
        border-radius: 2px;
        width: 15px;
        height: 15px;
        color: white;
      }

      .list
      {
        .item{
          display: flex;
          flex-flow: row;
          align-items: center;
        }

        button{
          margin-right: 5px;
        }

        input{
          margin: 5px 0;
        }
      }
    }
  }
}