form{
  
  width: 100%;
    
  input{
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px; 

    // radio button
    &[type=radio] + label::before{
      top: 2px;
    }
    // radio mark
    &[type=radio]:checked + label::after{
      top: 4px;
    }

    // date
    &[type=date]{
      width: 110px;
    }
    
    // checkbox label
    &[type=checkbox] + label{
      z-index: 1;
      height: 20px;
      line-height: 20px;       
      padding: 0 0 0 20px;
    } 
    &[type=checkbox] + label:hover {
      text-decoration: none;
    }
    // checkbox button
    &[type=checkbox] + label::before 
    {
      width: 12px;
      height: 12px;
      top: 1px;
      border: 1px solid black;
      background-color: white;
    }
    // checkbox mark
    &[type=checkbox]:checked + label::after {
      top: 7px;
      left: 4px;
      font-weight: bold;
      font-size: 1rem;
      color: black;
    }
  }
  
  select{
    width: 100%;
    height: 30px;
    border-radius: 5px;
  }
  
  textarea{
    width: 100%;
  }
}