.table
{ 
  &.gsc-keywords
  {
    /** Table DOM element for thematiques view **/  
    .table-header{
      thead
      {
        /** Children titles header **/
        tr:nth-child(2){
          th{
            &.col-label{
              .column-header-container{
                #filter-label::after{
                  left: 40%;
                }
              }
            }
            &.col-gsc{
              .column-header-container{
                #filter-gsc\.position::after{
                  left: 50%;
                }
              }
            }
            &.col-decisions{
              .column-header-container{
                #filter-action::after{
                  left: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}