.keyword-container{
  .keyword{
    .label-container
    {
      width: 80%;

      input{
        width: 100%;
      }
    }
  }
}