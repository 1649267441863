.table
{ 
  &.urls{

    /** Table DOM element for thematiques view **/  
    .table-header{
      thead
      {
        /** Children titles header **/
        tr:nth-child(2){
          th{
            &.col-perfs{
              .column-header-container{
                #filter-perfme::after{
                  left: 54%;
                }
              }
            }
            &.col-gsc{
              .column-header-container{
                #filter-gsc\.position::after{
                  left: 66%;
                }
              }
            }
            &.col-decisions{
              .column-header-container{
                #filter-action::after{
                  left: 92%;
                }
              }
            }
          }
        }

        /** TOTAL row **/
        tr.totalrow{      
          th{        
            .data-container
            {
              display: flex;
              flex-flow: row;
              justify-content: space-between;
              align-items: center;

              .right-container{
                font-size: 0.85rem;
                font-weight: 300;
                color: #9F9F9F;

                .variation{
                  font-weight: 300;
                }
              }
            }
          }
        }
      }
    }

    /*****************/
    /** Start Cells **/
    /*****************/
    .url-container{

      .url{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 5px;

        .label{
          overflow-wrap: break-word;
          max-width: 100%;
        }
        .label:hover{
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .keywords-link-container
      {
        display: flex;
        flex-flow: row;

        .keywords-link{
          display: flex;
          flex-flow: row;
          align-items: center;
          text-transform: uppercase;
          font-size: 0.75rem;        
        }
        .keywords-link:hover{
          text-decoration: underline;
        }
      }
    }

    .perfs-container{
      .tooltip{
        .tooltip-container{
          table{

            h2{
              font-weight: 300;
              font-style: italic;
              font-size: 1.1rem;
            }

            tr
            {
              /* Use for buttons to go to keywords screen */
              &.assigned-keywords-details,
              &.non-assigned-keywords-details{
                background: none;
                text-align: center;                
                height: 50px;

                td{
                  vertical-align: middle;
                }

                button{                  
                  width: 300px;
                  color: white;
                  text-transform: uppercase;
                  background-color: #e43e21;
                  border: solid 1px white;
                  border-radius: 5px;
                  padding: 5px;
                }
              }

              /* User for assigned and non assigned keywords */
              td.datas
              {
                padding-left: 5px;

                .internal{
                  max-width: 370px;
                  font-weight: 400;

                  .volume{
                    font-weight: 300;
                  }
                }
                
                ul{
                  margin: 0;
                  padding: 0;
                }  

                &.others-urls{
                  font-style: italic;

                  span{
                    font-weight: 400;                      
                  }
                }

                .expected-url{
                  color: #e43e21;
                  font-weight: 400;
                  font-style: italic;

                  .internal{
                    color: #e43e21;
                  }
                }
              }
  
              td.link-serp-viewer{
                width: 30px;
                vertical-align: middle;
                padding-right: 5px;
              }

              /* User for Others urls in assigned keyword table */
              td{
                table{
                  tbody{
                    tr{
                      &:nth-child( even ) {
                        background: none;
                      }
                    }
                  }                  
                }
              }   
            }
          }
        }
      }
    }
    /*****************/
    /** End Cells **/
    /*****************/
  }
}