.table
{ 
  &.keywords
  {
    .table-header{
      thead
      {
        /** TOTAL row **/
        tr.totalrow{      
          th{        
            .data-container
            {
              display: flex;
              flex-flow: row;
              justify-content: space-between;
              align-items: center;

              .right-container{
                font-size: 0.85rem;
                font-weight: 300;
                color: #9F9F9F;

                .variation{
                  font-weight: 300;
                }
              }
            }
          }
        }

        /** Children titles header **/
        tr:nth-child(2){
          th{
            &.col-gsc{
              .column-header-container{
                #filter-gsc\.position::after{
                  left: 66%;
                }
              }
            }
          }
        }
      }
    }

    /*****************/
    /** Start Cells **/
    /*****************/
    .keyword-container
    { 
      .keyword{
        display: flex;
        flex-flow: row;
      }

      .categories{
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        font-size: 0.8rem;
        text-transform: uppercase;
        color: #979797;
      }

      .keyword,
      .categories{
        width: 100%;

        .label{
          overflow-wrap: break-word;
        }
        .label:hover{
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .action-checkbox{
        margin-right: 10px;
        margin-left: 5px;
        z-index: 2;

        &[type=checkbox] + label{
          padding: 0 0 0 20px;
          text-decoration: none;
        }

        &[type=checkbox] + label::before {
          left: 0;
          top: 2px;    
          width: 12px;
          height: 12px;
          border: 1px solid #e43e21;
        }

        &[type=checkbox]:checked + label::after {
          left: 3.5px;
          top: 8.5px;
          color: #e43e21;
        }
      }    
    }
    /*****************/
    /** End Cells **/
    /*****************/
  }
}