.table
{ 
  &.admin-add-keywords
  {
    /** Table DOM element for thematiques view **/  
    .table-header
    {
      thead
      {
        /** Children titles header **/
        tr:nth-child(2){
          th{
            &.col-label{
              .column-header-container{
                #filter-label::after{
                  left: 50%;
                }
              }
            }
            &.col-decisions{
              .column-header-container{
                #filter-action::after{
                  left: 50%;
                }
              }
            }
          }
        }
        tr:nth-child(4)
        {
          height: 70px;
          background-color: white;
          box-shadow: 0 -1px 5px rgba(126, 120, 120, 0.5);
          
          th{
            &.col-decisions,
            &.col-categories-editor{
              padding: 0 10px;
            }

            &.col-label
            {
              .keyword-container 
              {
                display: flex;
                flex-flow: row;
                align-items: center;
              
                .keyword 
                {
                  position: relative;
                  height: 30px;
                  width: 90%;

                  .loader-container{
                    position: absolute;
                    width: 100%;
                    height: 100%;

                    ul{
                      margin-top: 40px;
                    }
                  }
    
                  .label-container
                  {
                    height: auto;
                    width: 100%;
    
                    input{
                      height: 30px;
                      line-height: 30px;
                      box-sizing: border-box;
                      padding: 0 0 0 5px;
                      border: solid 0.5px #988c8c;              
                    }
    
                    .react-autosuggest__container{
                      position: relative;
                      font-size: 1rem;
                      font-weight: 300;
                      text-align: left;
                    }
                    .react-autosuggest__suggestions-container--open{
                      width: 100%;
                      background: white;
                      color: #a6a6a6;
                      border-radius: 0 0 5px 5px;
                      border: solid 0.5px #988c8c;
                      border-top: none;
                      
                      .react-autosuggest__suggestions-list
                      {
                        padding: 10px 10px;
                        margin: 0;
                
                        .highlight{
                          color: black;
                          font-weight: bold;
                        }
                        
                        li{
                          display: block;
                          width: 280px;
                          height: 25px;
                          padding: 2px 0;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          direction: rtl;
                          cursor: pointer;
                
                          &:hover{
                            text-decoration: underline;
                          }
                        }
                      }
                    }
                  }
                }

                button
                {
                  width: 25px;
                  height: 25px;
                  margin-left: 10px;
                  border: none;
                  border-radius: 5px;
                  font-weight: 400;
                  color: white;
                  background-color: #e43e21;
                }
              }
            }
          }         
        }
      }
    }

    .table-body{
      .keyword-container
      {
        position: relative;

        .delete-validator-container
        {
          position: absolute;
          width: 100%;
          height: 100%;          
          display: none;
          flex-flow: row;
          justify-content: space-around;
          border-radius: 5px;
          background-color: #e43e21;
          z-index: 2;

          &.on{
            display: flex;
          }

          .description
          {
            display: flex;
            flex-flow: row;
            align-items: center;
            width: 75%;
            padding: 5px;

            svg{
              color: white;
              margin-right: 10px;
            }
            
            p{
              margin: 0;
              color: white;
              width: 100%;
              height: 100%;
            }
          }

          .confirm-container
          {
            width: auto;
            border-top: none;
            border-left: solid 0.5px white;
            margin: 0;
            padding: 0;

            .confirm,
            .reset{
              height: 80%;
              margin: auto 0 auto 20px;
            }

            .confirm{
              width: 30px;
              height: 30px;
            }
          }
        }

        .keyword 
        {
          height: 20px;

          label{
            top: 2px;
          }

          button.bin{
            color: #e43e21;
            margin: 0 5px 0 0;
          }

          .label-container
          {
            height: auto;
            width: 100%;
            position: relative;

            .label{
              height: 20px;
              line-height: 20px;
            }

            input{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              border: solid 0.5px #988c8c;          
              display: none;

              &.on{
                display: block;
              }    
            }

            .react-autosuggest__container{
              position: relative;
              font-size: 1rem;
              font-weight: 300;
              text-align: left;
            }
            .react-autosuggest__suggestions-container--open{
              width: 280px;
              margin-top: -5px;
              padding: 0 10px;
              background: white;
              color: #a6a6a6;
              border-radius: 0 0 5px 5px;
              border-top: solid 1px #e43e21;
              
              .react-autosuggest__suggestions-list
              {
                padding: 0;
                margin: 0;
        
                .highlight{
                  color: black;
                  font-weight: bold;
                }
                
                li{
                  display: block;
                  width: 280px;
                  height: 25px;
                  padding: 2px 0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  direction: rtl;
                  cursor: pointer;
        
                  &:hover{
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.popup-content{
  text-align: center;
  color: white;
  font-size: 1.2rem;

  p{
    margin: 5px 0 0;
  }
}