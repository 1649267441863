@import './../../assets/css/fonts.scss';

*{
  scrollbar-color: #a4a2a2 #e7e6e6; /* thumb and track color */
  scrollbar-width: 4px;
}

/** scrollbar **/
*::-webkit-scrollbar {
  width: 7px;
}
 
*::-webkit-scrollbar-thumb {
  background: #a4a2a2;
  border-radius: 4px;
}

*::-webkit-scrollbar-track {
  background: #e7e6e6;
  border-radius: 4px;
}

svg{
  overflow: visible;
}

.bidi-override{
  unicode-bidi: bidi-override;
}

html{
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

header{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  background-color:#e43e21;
  z-index: 10;

  .userinfos{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-right: 50px;

    .identity{
      color: white;
      font-size: 1.2rem;
      font-weight: bold;
    }

    .logout{
      color: white;
    }
  }
}

.sticky-container{
  z-index: 8;
  height: 118px;

  .sticky{
    .top-docker{
      .top-docker-title{
        display: none;
      }
      .top-docker-filters{
        .filter-container{
          margin: 5px 0;
        }
      }
    }
  }
}

a{
  text-decoration: none;
  color: black;
}

input{
  outline: none;
}

h1{
  display: flex;
  flex-direction: column;  
  color: white;  
  text-align: center;
  margin-left: 40px;
  
  .title{
    font-size: 2.28rem;
    font-weight: bold;
  }

  .subtitle{
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: 0.48px;
  }
}

h3{
  color: white;
  font-size: 0.8rem;
  font-weight: 200;
  margin: 0;
}

button{
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

ul{
  list-style: none;
}

.wrapper{
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 100%;
}

aside{
  position: absolute;
  top: 80px;
  left: 0;
  width: 230px;
  z-index: 9;
  cursor: pointer;

  /* Effets */
  transition: left 400ms ease-out;

  &.inactive{
    left: -215px;

    /* Effets */
    transition: left 200ms ease-out;

    .left-filter-wrapper ul li .text-link{
      display: none;
    }
    .left-filter-wrapper ul li .picto-link{
      border-radius: 10px;
    }
  }
}

section{
  width: 100%;
  background-color: #f5f2f2;

  .dashboard-wrapper{
    display: flex;
    flex-flow: column wrap;
    margin: 0 auto;
    width: 100%;    

    .top-docker{
      display: flex;
      flex-direction: column; 
      width: 100%;
      padding: 10px 0 10px 0;
      background-color: #e9e4e3;

      .top-docker-title,
      .top-docker-filters{
        margin: 0 20px 0 90px;
      }

      .top-docker-title{
        h2{
          font-family: PlayfairDisplay;
          font-size: 2.57rem;
          color: #e43e21;
          margin: 0;
        }
      }

      .top-docker-filters{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .filter-container{
          margin: 15px 0 5px 0;
        }
      }
    }
  }
}

footer{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}

/** input type radio **/
input[type=radio] + label{
  padding: 0 0 0 15px;
  position: relative;
  cursor: pointer;
}
input{
  &[type=radio] {
    display: none;
  }
  
  &[type=radio] + label::before,
  &[type=radio] + label::after {
    content: '';
    position: absolute;
    border-radius: 50%;
  }
  
  &[type=radio] + label::before {
    display: inline-block;
    left: 0;
    top: 0;    
    width: 10px;
    height: 10px;
    border: 1px solid white;
    background: #e43e21;
  }
  
  &[type=radio]:checked + label::after {
    display: inline-block;
    left: 2px;
    top: 2px;
    width: 8px;
    height: 8px;
    background: white;
  }
}

/** input type checkbox **/
input[type=checkbox] + label{
  padding: 0 0 0 15px;
  position: relative;
  cursor: pointer;

  &:hover{
    text-decoration: underline;
  }
}

input[type=checkbox]:checked + label{
  font-weight: 400;
}

input{
  &[type=checkbox] {
    display: none;
  }
  
  &[type=checkbox] + label::before,
  &[type=checkbox] + label::after {
    content: '';
    position: absolute;
  }
  
  &[type=checkbox] + label::before {
    left: 0;
    top: 2px;    
    width: 8px;
    height: 8px;
    border: 1px solid white;
    background: transparent;
  }
  
  &[type=checkbox]:checked + label::after {
    content: 'x';
    left: 2px;
    top: 6.5px;
    width: 10px;
    height: 10px;
    line-height: 0;
    margin: 0;
    padding: 0;
    background: transparent;
    color: white;
  }  
}

/** Select */
.react-select
{
  position: relative;
  width: 92px;
  background-color: white;
  border: solid 1px #e43e21;
  border-radius: 15px 15px;  
      
  .react-select__control{
    height: 17px; 
    min-height: 17px;
    font-weight: bold;
    border-radius: 15px 15px;
    
    .react-select__value-container{
      padding: 0;

      .react-select__input-container{
        padding: 0;
        margin: 0;

        .react-select__input{
          height: 17px;
          color: transparent!important;
        }
      }
      .react-select__placeholder,
      .react-select__single-value{
        color: #e43e21;
        font-size: 0.71rem;
        white-space: nowrap;
      }
      
      &.react-select__value-container--has-value,
      &.react-select__value-container--has-value + div{
        background-color: #e43e21;
        border-radius: 15px 0 0 15px;
        color: white;
        
        .react-select__single-value{
          color: white;
        }
      }
      &.react-select__value-container--has-value + div {
        border-radius: 0 15px 15px 0;

        svg{
          fill: white;
        }
      }
    }    
  }
  .react-select__control--is-disabled
  {
    .react-select__value-container
    {
      &.react-select__value-container--has-value,
      &.react-select__value-container--has-value + div{
        display: none;
      }
    }
  }

  .react-select__indicators{
    height: 17px;

    .react-select__indicator{
      padding: 0;

      svg{
        fill: #e43e21;
      }
    }

    .react-select__clear-indicator{
      svg{
        width: 0.9rem;
      }
    }
  }
  
  .react-select__menu{
    font-size: 0.71rem;
  }
}

/** Tooltip **/
.__react_component_tooltip.tooltip{
  display: flex;
  flex-flow: row wrap;
  border-radius: 5px;
  font-size: 0.85rem;
  font-weight: 200;
  border: solid 1px #e43e21;
  
  &.red{
    background-color: #e43e21;
    color: white;
  }

  &.grey{
    background-color: #f0f0f0;
    color: black;
  }
}

.__react_component_tooltip.tooltip{
  opacity: 1 !important;
}

/** Popup **/
.popup-overlay{
  .popup-content{
    width: 400px;
    height: auto;
    padding: 20px 20px;
    background-color: #e43e21;
    border-radius: 10px;
    border: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);

    .description{
      display: flex;
      flex-flow: row wrap;

      p{
        margin: 0 0 0 20px;
        width: 300px;
        font-family: PlayfairDisplay;
        color: white;
        line-height: 1rem;
      }

      svg{
        fill: white;
      }
    }
    .confirm-container{
      margin-top: 20px;
      
      .confirm{
        width: auto;
        padding: 0 10px;
      }
    }
  }
}