.table
{ 
  &.thematics{

    /** Table DOM element for thematiques view **/
    .table-body{
      tbody{
        tr{
          background-color: #E5EEF5;
                              
          &.parentRow{
            background-color: rgba(113, 184, 242, 0.29);
            box-shadow: 0 -3px 5px rgba(126, 120, 120, 0.4) inset;
          }

          td{
            border-color: #CBE5FA;
          }
        }
      }
    }

    .table-header{
      thead{
        /** Children titles header **/
        tr:nth-child(2){
          th{
            &.col-label{
              .column-header-container{
                #filter-label::after{
                  left: 40%;
                }
              }
            }
            &.col-perfs{
              .column-header-container{
                #filter-perfgp1::after,
                #filter-perfgp2::after,
                #filter-perfgp3::after{
                  left: 54%;
                }
              }
            }
            &.col-gsc{
              .column-header-container{
                #filter-ctr::after{
                  left: 72%;
                }
                #filter-position::after{
                  left: 97%;
                }
              }
            }
          }
        }
      }
    }

    /*****************/
    /** Start Cells **/
    /*****************/
    .thematic-container{
      .thematic,
      .previous-link{
        width: 140px;
        font-size: 1rem;
        font-weight: bold;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.clickable:hover{
          text-decoration: underline;
        }
      }
      .previous-link{
        font-weight: 300;
      }
      .keywords-link{
        display: flex;
        flex-flow: row;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.75rem;
      }
      .keywords-link:hover,
      .previous-link:hover{
        text-decoration: underline;
      }
    }

    .volume-container,
    .snippets-container,
    .gsc-container,
    .perfs-container
    {
      .line-container{
        .negative-line{
          background-color: #d4d2d1;
        }
      }
      
      .tooltip{
        .tooltip-container{
          table{

            thead{
              tr{
                background-color: transparent;
              }
            }

            tbody{
              tr{
                background-color: transparent;

                td{
                  &.type-snippet{
                    width: 100%;
    
                    .variation{
                      font-weight: 300;
                    }
                  }
                }
              }          
            }            
          }
        }
      }
    }
    /*****************/
    /** End Cells **/
    /*****************/
  }  
}